
/* BETWEEN GALLERY */
#termy {
    padding-bottom:2%;
    background-color: DodgerBlue;
    width: 100%;
font-family:MyFont;
color:white;

}
.termtext{
    padding:3%;
    font-size:115%;
}

.termbox{
    
    background-color: rgb(12, 95, 177);

}

@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(./MyFont.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
           font-family:Actor;                   'embedded-opentype', and 'svg' */
  }
