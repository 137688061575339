
/* IMG GALLERY */
.img-gallery {
    padding: 0px !important;
    margin: 0px;
}

.img-gallery .row {
    --bs-gutter-x: 0;
}

.img-gallery .row > * {
    margin: 0px;
    padding: 0px;
    background-color: black;
}

.img-gallery img {
   width: 100%;
   height: auto;
   margin: 0px;
   padding: 0px;
   border-top: 10px solid black;
   border-bottom: 10px solid black;
   border-left: 5px solid black;
   border-right: 5px solid black;
}

