#banner {
    background-color: DodgerBlue;
    display: flex;
    justify-content: center;
    margin-bottom: -9%;
}


#bannery {
    background-color: DodgerBlue;
    display: flex;
    justify-content: center;
    margin-bottom: -10%;
}
#bannerimg {
    width: 100%;
    height: auto;
    max-width: 930px;
}

.navbar-navy{
   font-size: 3.2em;
    display:flex;
    justify-content:space-evenly;
}