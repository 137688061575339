
/* BETWEEN GALLERY */
#between-gallery {
    padding-bottom: 70px;
    padding-top: 50px;
    background-color: #262022;
    width: 100%;
font-family:MyFont;
color:white;
}
.flurky{
    padding:5%;
}
.imgshadow{
    -moz-box-shadow:    -3px 3px 10px 6px rgb(27, 27, 27);
    -webkit-box-shadow: -3px 3px 10px 6px rgb(27, 27, 27);
    box-shadow:         -3px 3px 10px 6px rgb(27, 27, 27);
}
.imgrounded{
    border-radius: 15px;
}
.flurk2{
    background-color: #433C44;
    -moz-box-shadow:    -3px 3px 10px 6px rgb(27, 27, 27);
    -webkit-box-shadow: -3px 3px 10px 6px rgb(27, 27, 27);
    box-shadow:         -3px 3px 10px 6px rgb(27, 27, 27);
    border-radius: 15px;
}
.flurk1{
    -moz-box-shadow:    -3px 3px 10px 6px rgb(27, 27, 27);
    -webkit-box-shadow: -3px 3px 10px 6px rgb(27, 27, 27);
    box-shadow:         -3px 3px 10px 6px rgb(27, 27, 27);
    background-color: #322b33;
    padding-bottom: 70px;
    padding-top: 50px;    border-radius: 15px;
}
.minty{

    color:white;
}
@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(./MyFont.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
           font-family:Actor;                   'embedded-opentype', and 'svg' */
  }
