#hero{
    align-items:center;
    background-color: DodgerBlue;
    justify-content:center;
    padding-bottom:4%;
    padding-top:17.5%;
    text-align:center
}
#hero,#herorow{
    display:flex;
    flex-flow:row;
 
}
#hero img{
    
    box-shadow:-3px 3px 10px 6px rgb(27, 27, 27);

}
#hero img,.mint-hero{
    height:auto;
    max-width:700px;
 
    width:100%;    border-radius: 15px;
}
.mint-hero{
    background-color: LightSkyBlue;
     -moz-box-shadow:    -3px 3px 10px 6px rgb(27, 27, 27);
    -webkit-box-shadow: -3px 3px 10px 6px rgb(27, 27, 27);
    box-shadow:         -3px 3px 10px 6px rgb(27, 27, 27);
    border-radius:5px;
    color:#000;
    flex-flow:column;
    font-size:2.5em;
    border-radius: 15px;
    justify-content:center;
    padding-top:9%;  
    padding-bottom:9%;
    width:30%;
    text-align:center;

}

#herominted,.mint-hero{
    display:flex;
 
     font-family:MyFont;
}
#gay,.gay{
    display:flex;
    width:25%;
    padding-right:1%;
     font-family:MyFont;
}
#heroremaining,.heroremaining{
     font-size:75%;
     font-family:MyFont;
     margin-top:-10%;
     color:rgb(226, 0, 0);
}
#buyFrawgs-result,.buyFrawgs-result{
  
    font-size:60%;
    font-family:MyFont;
    margin-top:-5%;
    margin-bottom:-3%;
}
#herominted{
    border-bottom:1px solid gray;
    flex-flow:row;
    font-weight:100;
    justify-content:space-evenly;
    font-size:90%;
}
#remaining-supply{
    background-color:LightCyan;
    color:#000;
        font-family:MyFont;
    margin-left:20px;
    margin-right:20px;
    padding:7px;
    display:flex;
    flex-wrap:nowrap ;
    flex-basis :10%;
    justify-content:space-evenly;
    font-size:90%;
}
#mint-input{
    display:inline-block;
    font-size:35px;
    margin-left:10px;
    padding:7px 7px 3px;   
    width:70px;
    border-radius: 15px;
    margin-top:3%; 
    -moz-box-shadow:    -2x 2px 2px 2px rgb(27, 27, 27);
    -webkit-box-shadow: -2px 2px 2px 2px rgb(27, 27, 27);
    box-shadow:         -2px 3px 2px 2px rgb(27, 27, 27);
}
#mintinputrow{
    align-items:center;
    display:flex;
    flex-flow:row;
    justify-content:center;
    padding-top:10px
}
.countdown{
    display:flex;
    justify-content:space-evenly;
    font-size:90%;   
}
.price{
    display:flex;
    justify-content:space-evenly;
    font-size:90%;   
}
#mint-input[type=number]::-webkit-inner-spin-button,#mint-input[type=number]::-webkit-outer-spin-button{
    opacity:1;
 
    
}
.mint-row{

    margin-bottom:20px;
    margin-top:20px!important
}
#mint-button{
    
    font-family:MyFont;
    font-size:30px;
    width:40%;
    -moz-box-shadow:    -2x 2px 2px 2px rgb(27, 27, 27);
    -webkit-box-shadow: -2px 2px 2px 2px rgb(27, 27, 27);
    box-shadow:         -2px 3px 2px 2px rgb(27, 27, 27);
    border-radius: 15px;
}
#rarible-button{
    
    font-family:MyFont;
    font-size:30px;
    -moz-box-shadow:    -2x 2px 2px 2px rgb(27, 27, 27);
    -webkit-box-shadow: -2px 2px 2px 2px rgb(27, 27, 27);
    box-shadow:         -2px 3px 2px 2px rgb(27, 27, 27);
    border-radius: 15px;
}
.hero-minted{
    padding-bottom:1%;   
    
}



.col-3{

    background-color: LightCyan;
    border-radius: 15px;
    margin:1%;    
}

@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(./MyFont.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
           font-family:Actor;                   'embedded-opentype', and 'svg' */
  }
