
/* Show it is fixed to the top */



/* NAVBAR */
.navbar .container {
    padding: 10px;
    font-size: 1.2em;
}
