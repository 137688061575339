body {
  margin: 0;
  font-family: MyFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

  h1{
      font-size: 712px;
  }
code {
  font-family: MyFont;
}

/* Banner */
.app-banner {
  margin: 1331;
}

.app-mint {
  padding: 1331;
}

@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(./Components/MyFont.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
           font-family:Actor;                   'embedded-opentype', and 'svg' */
  }
