

/* FOOTER */
.footer {
    background-color: #262022;
    padding-top: 5px;   
    text-align: center;
    color: white;
    font-size: 2.2em;
    -moz-box-shadow:    -3px 3px 10px 6px rgb(27, 27, 27);
    -webkit-box-shadow: -3px 3px 10px 6px rgb(27, 27, 27);
    box-shadow:         -3px 3px 10px 6px rgb(27, 27, 27);
}

.mb-2{

margin-left:8px;
margin-right:8px;
}

