#between-gallery2 {
    padding-bottom: 70px;
    padding-top: 50px;
    background-color: DodgerBlue;
    width: 100%;
    font-family:MyFont;  
color:#262022;
}

@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(./MyFont.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
           font-family:Actor;                   'embedded-opentype', and 'svg' */
  }


  /* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: LightSkyBlue;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    padding:1%;
    padding-left:2%;
    padding-top:2%;
    margin:0.5%;
    outline: none;
    transition: 0.4s;
    border-radius: 15px;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .accordion:hover {
    background-color: LightCyan;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0.5% 18px;  
    display: none;
    overflow: hidden;
  
  }

  .answer{
      font-size:30px;
  }

  a{
    color:white;
  }